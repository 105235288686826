import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {Link, useParams} from "react-router-dom";
import {houseApi} from "../../../api/boarding_school";

import {LazyLoadImage} from "react-lazy-load-image-component";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import env from "react-dotenv";
import {PrimaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    ...env.SCHOOL === 'KPI' ? [
    {
        accessorKey: "current_plupu_rating",
        id: "current_plupu_rating",
        header: "Status",
        columnType: "plupu_rating",
        enableColumnFilter: false
    }] : [],
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "main_mentor",
        id: "main_mentor",
        header: "Stammmentor",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
];

export default function HouseDetail() {
    const {houseId} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo();

    const canViewHouseLog = checkPermission(permissions, "boarding_school.view_houselogentry");

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["houses", houseId],
        () => houseApi("get", houseId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/school/students/${row.original.id}/`;
    }

    if (!data) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{data.name}</h1>
                            {/*<p className="text-sm font-medium text-gray-500">{student.student_number}</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        {/*<SecondaryButton label="Schülerblatt" link={`/students/${studentId}`}/>*/}

                        {canViewHouseLog &&
                            <PrimaryButton link={`/boarding-school/houses/${houseId}/house-log`}
                                           label="Dienstbuch"/>}
                    </div>
                </div>

                <div
                    className="mt-8 mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-4">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2
                                        id="notes-title"
                                        className="text-lg font-medium text-gray-900"
                                    >
                                        Schüler
                                    </h2>
                                </div>
                                <div className="mt-0">
                                    {isSuccess && data && (
                                        <ListViewTable
                                            data={data.students}
                                            columns={columns}
                                            isLoading={isLoading}
                                            disableGlobalSearch={true}
                                            getRowLink={getRowLink}
                                            noTopSpace={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-4 lg:col-span-1"
                    >
                        {data && (
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Mentoren
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <ul role="list" className="divide-y divide-gray-200">
                                            {data.mentors.map((mentor, index) => (
                                                <Link key={index} to={`/staff/${mentor.id}`}>
                                                    <li className="sm:px-6 flex py-4">
                                                        {renderUserImage(mentor.user_id)}
                                                        <div className="ml-3">
                                                            <p className="text-sm font-medium text-gray-900 py-2">
                                                                {mentor.full_name}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </Link>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        )}
                    </section>
                </div>
            </main>
        </>
    );
}
