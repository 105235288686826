import {Page} from "../../elements/Pages";
import {React, useEffect, useMemo, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, ErrorDisplay, Input, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {
    extraExamApi,
    extraExamLineApi,
    getExamTypeOptions,
    getExtraExamWarnings,
    getStudentInfoAll
} from "../../../api/school";
import Form, {baseHandleDateChange, baseHandleListChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {DeleteButton} from "../../elements/DeleteButton";
import {AddButton} from "../../elements/Buttons";
import {getStudentCourses} from "../../../api/courses";
import Alert from "../../elements/Alert";


export default function ExtraExamCreateEdit() {

    const {id} = useParams()

    const [data, setData] = useState({
        id: null,
        date: null,
        extra_exam_lines: []
    })

    const {data: students} = useQuery(['student_info'], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: studentsCourses} = useQuery(["student_course_list"], getStudentCourses, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: examCategories} = useQuery(['exam_categories'], getExamTypeOptions, {refetchInterval: 1000 * 60 * 60 * 24})

    const [newRowId, setNewRowId] = useState(1)
    const [examWarnings, setExamWarnings] = useState({})
    const [errors, setErrors] = useState({})
    const [listErrors, setListErrors] = useState([])

    let navigate = useNavigate();

    const lineSchema = {
        student: Joi.object().required(),
        course: Joi.object().required().messages({"object.base": "Bitte einen Kurs auswählen"}),
        work_time: Joi.string().required(),
        category: Joi.string().required(),
    }

    const schema = {
        date: Joi.date().required(),
        extra_exam_lines: Joi.array().items(lineSchema).min(1),
    }

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await extraExamApi('get', id)
        setData(newData)
    }

    const studentIds = useMemo(() => {

        let studentIds = []
        for (let i = 0; i < data.extra_exam_lines.length; i++) {
            if (data.extra_exam_lines[i].student) {
                studentIds.push(data.extra_exam_lines[i].student.id)
            }
        }
        return studentIds

    }, [data.extra_exam_lines]);

    useEffect(() => {
        loadExamWarnings()
    }, [data.date, studentIds])

    async function loadExamWarnings() {
        if (!data.date || !data.extra_exam_lines) return
        const newData = await getExtraExamWarnings(studentIds, data.date, data.id)
        setExamWarnings(newData)
    }

    const title = (id || data.id) ? "Nachholklausur bearbeiten" : "Nachholklausur eintragen"
    const successMessage = (id || data.id) ? "Nachholklausur aktualisiert" : "Nachholklausur eingetragen"

    async function onSave() {

        let newExtraExamLines = data.extra_exam_lines.map((t, index) => {
            if (t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })

        const tmpdata = {...data, extra_exam_lines: newExtraExamLines}

        let newData
        if (!data.id) newData = await extraExamApi('post', null, tmpdata)
        else newData = await extraExamApi('patch', null, tmpdata)
        setData(newData)
        toast.success(successMessage)
        navigate(`/class-register/extra-exams/${newData.id}`)
    }

    async function onDelete() {
        await extraExamApi('delete', data.id)
        toast.success("Nachholklausur gelöscht.")
        navigate(`/class-register/extra-exams`)
    }

    function setExtraExamLines(newLines) {
        setData({...data, extra_exam_lines: newLines})
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.extra_exam_lines, setExtraExamLines, listErrors, setListErrors, lineSchema)
    }

    function handleStudentChange(evt, rowId) {
        const extra_exam_lines = getExtraExamLines(evt, rowId)
        baseHandleListChange(rowId, evt, extra_exam_lines, setExtraExamLines, listErrors, setListErrors, lineSchema)
    }

    async function handleDeleteExtraExamLine(rowId) {
        if (rowId.toString().startsWith("N")) {
            const newData = data.extra_exam_lines.filter(row => row.id !== rowId)
            setExtraExamLines(newData)
        } else {
            try {
                await extraExamLineApi('delete', rowId)
                loadData()
                toast.success("Eintrag gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    function addRow(room) {
        const newRow = {
            id: `N${newRowId}`,
            student: null,
            course: null,
            work_time: null,
            topic: null,
            category: "Klausur"
        }
        // setSelectedRowId(`N${newRowId}`)
        setNewRowId(newRowId + 1)
        setData({
            ...data,
            extra_exam_lines: [...data.extra_exam_lines, newRow]
        })
    }

    function getAvailableCourses(student) {
        if (student) {
            const studentCoursesFound = studentsCourses?.students.find(sc => sc.id === student.id)
            return studentCoursesFound ? studentCoursesFound.courses : []
        }
        return []
    }

    function getExtraExamLines(evt, rowId) {
        return data.extra_exam_lines.map(exam_line => {
            if (exam_line.id === rowId) {
                const availableCourses = getAvailableCourses(evt.target.value)
                if (exam_line.course) {
                    const course = availableCourses.find(course => course.id === exam_line.course.id)
                    if (course) {
                        return exam_line
                    }
                }
                return {...exam_line, course: null}
            }
            return exam_line
        })
    }

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="space-y-6 lg:col-span-6">

                    <Form id="createExtraExam" onSave={onSave}
                          onDelete={onDelete}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <FormSectionContent>

                            <DateInput
                                className="col-span-2"
                                path="date"
                                label="Datum"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                            />

                            <div className="sm:col-span-6 py-4">

                                <div className="overflow-hidden ">

                                    <h3 className="text-lg leading-6 font-medium text-gray-900">SchülerInnen</h3>

                                    {/* Errors */}
                                    <div className="break-word py-4 pl-4 pr-3 text-sm">
                                        <ErrorDisplay
                                            path="extra_exam_lines"
                                            label="Errors"
                                            errors={errors}
                                        />
                                    </div>

                                    <table className="min-w-full divide-y divide-gray-300">
                                        <tbody>
                                        {data.extra_exam_lines.map((line, index) => (
                                            <tr key={index}
                                                className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                            >
                                                <td className="break-word py-4 pl-4 pr-3 text-sm">

                                                    <Select
                                                        path="student"
                                                        label="SchülerIn"
                                                        options={students}
                                                        valueAttr="id"
                                                        labelAttr="full_name"
                                                        onChange={handleStudentChange}
                                                        errors={listErrors}
                                                        data={data.extra_exam_lines}
                                                        rowId={line.id}
                                                        isClearable={false}
                                                        valueOnly={false}
                                                    />
                                                </td>

                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                    <Select
                                                        path="course"
                                                        label="Kurs"
                                                        options={getAvailableCourses(line.student)}
                                                        valueAttr="id"
                                                        labelAttr="course_number"
                                                        onChange={handleListChange}
                                                        errors={listErrors}
                                                        data={data.extra_exam_lines}
                                                        rowId={line.id}
                                                        isClearable={false}
                                                        valueOnly={false}
                                                    />
                                                </td>

                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                    <Select
                                                        path="category"
                                                        label="Art"
                                                        options={examCategories}
                                                        valueAttr="value"
                                                        labelAttr="label"
                                                        onChange={handleListChange}
                                                        errors={listErrors}
                                                        data={data.extra_exam_lines}
                                                        rowId={line.id}
                                                        valueOnly={true}
                                                    />
                                                </td>

                                                <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                    <Input
                                                        path="topic"
                                                        label="Thema"
                                                        onChange={handleListChange}
                                                        errors={listErrors}
                                                        data={data.extra_exam_lines}
                                                        rowId={line.id}
                                                    />
                                                </td>

                                                <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                    <Input
                                                        path="work_time"
                                                        label="Arbeitszeit"
                                                        onChange={handleListChange}
                                                        errors={listErrors}
                                                        data={data.extra_exam_lines}
                                                        rowId={line.id}
                                                    />
                                                </td>

                                                <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                    <DeleteButton
                                                        onDelete={() => handleDeleteExtraExamLine(line.id)}/>
                                                </td>

                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                </div>

                                <div className="flex justify-between">
                                    <div className="my-4 ml-4">
                                        <AddButton onClick={addRow} label="SchülerIn hinzufügen"/>
                                    </div>
                                </div>

                            </div>

                            {examWarnings?.messages && examWarnings.messages.length > 0 &&
                                <div className="sm:col-span-6">
                                    <Alert variant="warning">
                                        <ul role="list" className="space-y-1">
                                            {examWarnings.messages.map((m, index) => (
                                                <li key={index}>{m}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </div>}

                        </FormSectionContent>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

                <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">
                </div>
            </div>


        </Page>
    )
}
