import http from "./../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";
import {genericApi} from "./generic";

export async function getStudentInfoAll() {
    const resp = await http.get("/student_list/");
    return resp.data;
}

export async function getStudentInfoAllWithImages() {
    const resp = await http.get("/student_list/with_images/");
    return resp.data;
}


export async function getStudentListWithMainMentor() {
    const resp = await http.get("/student_list/with_main_mentor/");
    return resp.data;
}

export async function getSmokers() {
    const resp = await http.get("/student_list/smokers/");
    return resp.data;
}

export async function getSmokersExcel() {
    const resp = await httpServiceDownloads.get("/student_list/smokers_excel/");
    return resp.data;
}

export async function getStudentInfo(studentId) {
    const resp = await http.get(`/student_list/${studentId}/`);
    return resp.data;
}

export async function getStudentInfoInclAlumni(studentId) {
    const resp = await http.get(`/student_list/${studentId}/incl_alumni/`);
    return resp.data;
}

export async function getMyStudents() {
    const resp = await http.get("/student_list/my_students/");
    return resp.data;
}

export async function getMyStudentsInclAlumni() {
    const resp = await http.get("/student_list/my_students_incl_alumni/");
    return resp.data;
}

export async function getCourseInfoAll() {
    const resp = await http.get("/course_list/");
    return resp.data;
}

export async function getStudentInfoGrouped(mode) {
    const resp = await http.get("/student_list/grouped/" + (mode ? `?mode=${mode}` : ''));
    return resp.data;
}

export async function getStudentInfoGroupedCourse() {
    const resp = await http.get("/student_list/grouped_by_courses/");
    return resp.data;
}

export async function getSchoolClasses() {
    const resp = await http.get("/school_classes/info_list/");
    return resp.data;
}

export async function getSchoolClassesFull() {
    const resp = await http.get("/school_classes/");
    return resp.data;
}

export async function getSchoolClass(id) {
    const resp = await http.get(`/school_classes/${id}/`);
    return resp.data;
}

export async function patchSchoolClass(id, data) {
    const resp = await http.patch(`/school_classes/${id}/`, data);
    return resp.data;
}

export async function getSchoolClassExcel(id) {
    const resp = await httpServiceDownloads.get(`/school_classes/${id}/excel/`)
    return resp.data
}

export async function getGrades() {
    const resp = await http.get("/grade_list/");
    return resp.data;
}

export async function getSchoolSubjects() {
    const resp = await http.get("/school_subjects/");
    return resp.data;
}

export async function getSchoolSubjectsForeignLanguages() {
    const resp = await http.get("/school_subjects/foreign_languages/");
    return resp.data;
}

export async function getStudents() {
    const resp = await http.get("/students/");
    return resp.data;
}

export async function getTerminatedStudents() {
    const resp = await http.get("/students/students_with_termination_date/");
    return resp.data;
}

export async function getStudent(studentId) {
    const resp = await http.get(`/students/${studentId}/`);
    return resp.data;
}

export async function getStudentTeachers(studentId) {
    const resp = await http.get(`/students/${studentId}/teachers/`);
    return resp.data;
}


export async function getNewStudents(dateFrom, dateTo) {
    const resp = await http.get(`/students/new_students/?entry_date_from=${dateFrom}&entry_date_to=${dateTo}`);
    return resp.data;
}

export async function getNewStudentsPDF(start, end) {
    const resp = await httpServiceDownloads.get(`/school/new_students_pdf/?start=${start}&end=${end}`)
    return resp.data
}

// student exports
export async function getStudentsBasicExcel() {
    const resp = await httpServiceDownloads.get(`/students/excel_basic/`)
    return resp.data
}

export async function getStudentsFullExcel() {
    const resp = await httpServiceDownloads.get(`/students/excel_full/`)
    return resp.data
}

// student documents
export async function getStudentDocuments(studentId) {
    const resp = await http.get(`/student_documents/?student=${studentId}`);
    return resp.data;
}

export async function studentDocumentApi(method, id, data) {
    return genericApi('/student_documents/', method, id, data)
}

export async function addFileToStudentDocument(formData, id) {
    const resp = await http.post(`/student_documents/${id}/add_file/`, formData)
    return resp.data
}


// exams
export async function getExamsByMonth(year, month, courseIds, schoolClassIds, studentIds) {
    const resp = await http.get(`/exams/month/?year=${year}&month=${month}`
        + (courseIds ? `&course_ids=${courseIds}` : ``)
        + (schoolClassIds ? `&school_class_ids=${schoolClassIds}` : ``)
        + (studentIds ? `&student_ids=${studentIds}` : ``)
    );
    return resp.data;
}

export async function examApi(method, id, data) {
    return genericApi('/exams/', method, id, data)
}

export async function getExamsWithSearch(studentIds) {
    const resp = await http.get(`/exams/?student_id=${studentIds}`);
    return resp.data;
}


export async function getExamTypeOptions() {
    const resp = await http.get(`/school/exam_type_options/`);
    return resp.data;
}

export async function getOtherExams(courseId, examDate, examId) {
    const resp = await http.get(`/exams/other_exams_for_course/?course_id=${courseId}`
        + (examDate ? `&date=${examDate}` : ``)
        + (examId ? `&exam_id=${examId}` : ``)
    )
    return resp.data;
}

// warnings
export async function getExamWarnings(courseId, examDate, examId) {
    const resp = await http.get(`/exams/exam_warnings/?course_id=${courseId}`
        + (examDate ? `&date=${examDate}` : ``)
        + (examId ? `&exam_id=${examId}` : ``)
    )
    return resp.data;
}

export async function getExamEntryOpenWarnings(courseId, examDate, examId) {
    const resp = await http.get(`/exams/exam_entry_open/?course_id=${courseId}`
        + (examDate ? `&date=${examDate}` : ``)
    )
    return resp.data;
}

export async function getExtraExamWarnings(studentIds, examDate, extraExam) {
    const resp = await http.get(`/exams/exam_warnings/?student_ids=${studentIds}`
        + (examDate ? `&date=${examDate}` : ``)
        + (extraExam ? `&extra_exam_id=${extraExam}` : ``)
    )
    return resp.data;
}

export async function getExamWarningsList() {
    const resp = await http.get(`/school/exam_warnings/`)
    return resp.data
}

export async function getExamsExcel() {
    const resp = await httpServiceDownloads.get(`/exams/excel/`)
    return resp.data
}

export async function getExamWarningsForAbsenceNotice(studentId, start, end) {
    const resp = await http.get(`/exams/exam_warnings_for_absence_notice/?student_id=${studentId}`
        + (start ? `&start=${start}` : ``)
        + (end ? `&end=${end}` : ``)
    )
    return resp.data;
}

// extra exams
export async function extraExamLineApi(method, id, data) {
    return genericApi('/extra_exam_lines/', method, id, data)
}

export async function extraExamApi(method, id, data) {
    return genericApi('/extra_exams/', method, id, data)
}

export async function getExtraExamPDF(extraExamId) {
    const resp = await httpServiceDownloads.get(`/extra_exams/${extraExamId}/pdf/`)
    return resp.data
}


// Facharbeit
export async function facharbeitApi(method, id, data) {
    return genericApi('/facharbeit/', method, id, data)
}

export async function getFacharbeitList(studentId) {
    const resp = await http.get(`/facharbeit/` + (studentId ? `?student_id=${studentId}` : ''))
    return resp.data
}

export async function getFacharbeitPDF(id) {
    const resp = await httpServiceDownloads.get(`/facharbeit/${id}/pdf/`)
    return resp.data
}

export async function getFacharbeitExcel() {
    const resp = await httpServiceDownloads.get(`/facharbeit/excel/`)
    return resp.data
}

export async function getMyFacharbeit() {
    const resp = await http.get(`/facharbeit/my/`)
    return resp.data
}

// GFS
export async function getGFSHalfYearOptions() {
    const resp = await http.get(`/school/gfs_half_year_options/`);
    return resp.data;
}

export async function gfsApi(method, id, data) {
    return genericApi('/gfs/', method, id, data)
}

export async function getGfsList(schoolYearId, type, studentId) {
    const resp = await http.get(`/gfs/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
        + (type ? `&type=${type}` : '')
        + (studentId ? `&student_id=${studentId}` : ''))
    return resp.data
}

export async function getGfsCompleteList() {
    const resp = await http.get(`/gfs/kursstufe_student_count/`)
    return resp.data
}

export async function getGfsPDF(id) {
    const resp = await httpServiceDownloads.get(`/gfs/${id}/pdf/`)
    return resp.data
}

export async function getGfsExcel(schoolYearId, type) {
    const resp = await httpServiceDownloads.get(`/gfs/excel/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
        + (type ? `&type=${type}` : '')
    )
    return resp.data
}

export async function getMyGfs() {
    const resp = await http.get(`/gfs/my/`)
    return resp.data
}

export async function getParentProfile(id) {
    const resp = await http.get(`/parent_profiles/${id}/`);
    return resp.data;
}

export async function getMyParentProfile() {
    const resp = await http.get("/parent_profiles/my_profile/");
    return resp.data;
}

export async function patchParentProfile(data) {
    const resp = await http.patch(`/parent_profiles/${data.id}/`, data);
    return resp.data;
}

export async function approveParentProfileChanges(id) {
    const resp = await http.post(`/parent_profiles/${id}/approve_changes/`);
    return resp.data;
}

export async function excursionApi(method, id, data) {
    return genericApi('/excursions/', method, id, data)
}

export async function approveExcursion(id) {
    const resp = await http.post(`/excursions/${id}/approve/`)
    return resp.data
}

export async function rejectExcursion(id) {
    const resp = await http.post(`/excursions/${id}/reject/`)
    return resp.data
}

export async function getEnrollmentCertificatePDF(studentId) {
    const resp = await httpServiceDownloads.get(`/school/enrollment_certificate/${studentId}/`)
    return resp.data
}

export async function getSchoolTypes() {
    const resp = await http.get("/school_types/");
    return resp.data;
}

export async function getLeadershipRoles() {
    const resp = await http.get("/leadership_roles/");
    return resp.data;
}

export async function getStudentLeadershipRoles() {
    const resp = await http.get("/student_leadership_roles/only_with_roles/");
    return resp.data;
}
export async function getStudentLeadershipRolesAll() {
    const resp = await http.get("/student_leadership_roles/");
    return resp.data;
}

export async function getStudentLeadershipRoleStudentDetail(studentId) {
    const resp = await http.get(`/student_leadership_roles/${studentId}/`);
    return resp.data;
}

export async function patchStudentLeadershipRoles(studentId, data) {
    const resp = await http.patch(`/student_leadership_roles/${studentId}/`, data);
    return resp.data;
}

export async function getStudentBookCodes(studentId) {
    const resp = await http.get(`/student_book_codes/${studentId}/`);
    return resp.data;
}

export async function patchStudentBookCodes(studentId, data) {
    const resp = await http.patch(`/student_book_codes/${studentId}/`, data);
    return resp.data;
}