import React from 'react';
import {Route, Routes} from "react-router-dom";
import CourseList from "./CourseList";
import CourseDetail from "./CourseDetail";
import CourseEdit from "./CourseEdit";
import CourseInstructionsCreateEdit from "./CourseInstructionsCreateEdit";
import CourseMaterialCreateEdit from "./CourseMaterialCreateEdit";
import StudentCourseList from "./StudentCourseList";
import StudentCourseDetail from "./StudentCourseDetail";
import TeacherCourseList from "./TeacherCourseList";
import CourseTopicsCourseList from "./CourseTopicsCourseList";
import CourseTopics from "./CourseTopics";
import MyCoursesTeacher from "./MyCoursesTeacher";
import MyCoursesStudent from "./MyCoursesStudent";
import MyCoursesStudentDetail from "./MyCoursesStudentDetail";
import StudentSchoolClassChange from "../school/StudentSchoolClassChange";
import MyCourseMaterials from "./MyCourseMaterials";
import MyCourseMaterialsStudent from "./MyCourseMaterialsStudent";


function CourseRouter() {

    return (
        <Routes>
            <Route path="list" element={<CourseList />} />
            <Route path=":id" element={<CourseDetail />} />
            <Route path=":id/edit" element={<CourseEdit />} />
            {/*<Route path="/new" element={<StaffList />} />*/}

            {/* Course materials */}
            <Route path=":courseId/course-material/new" element={<CourseMaterialCreateEdit />} />
            <Route path="course-material/:id/edit" element={<CourseMaterialCreateEdit />} />

            {/* Course instructions */}
            <Route path=":courseId/course-instructions/new" element={<CourseInstructionsCreateEdit />} />
            <Route path="course-instructions/:id/edit" element={<CourseInstructionsCreateEdit />} />

            <Route path="student-courses" element={<StudentCourseList />} />
            <Route path="student-courses/:studentId" element={<StudentCourseDetail />} />
            <Route path="teacher-courses" element={<TeacherCourseList />} />

            <Route path="school-class-change/:studentId/:newSchoolClassId" element={<StudentSchoolClassChange />} />

            {/* Course topics */}
            <Route path="course-topics" element={<CourseTopicsCourseList />} />
            <Route path=":courseId/course-topics" element={<CourseTopics />} />

            {/* My courses */}
            <Route path="/teacher/my" element={<MyCoursesTeacher />} />
            <Route path="/students/my" element={<MyCoursesStudent />} />
            <Route path="/students/course-materials/my" element={<MyCourseMaterials />} />
            <Route path="/students/:studentId" element={<MyCoursesStudentDetail />} />
            <Route path="/students/:studentId/course-materials" element={<MyCourseMaterialsStudent />} />

        </Routes>
    )
}


export default function Courses() {
    return (
        <CourseRouter />
    )
}
