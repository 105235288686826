import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {CalendarButton, DownloadButton, EditButton, PrimaryButton} from "../../elements/Buttons";
import {
    getExamsExcel, getExamsWithSearch,
    getStudentInfoAllWithImages,
    getStudentInfoGrouped,
    getStudentInfoGroupedCourse
} from "../../../api/school";
import checkPermission from "../../../utils/permissions";
import {MultiSelect} from "../../elements/forms/Inputs";
import StudentSelector from "../student_logbook/StudentSelector";

const columns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
    },
    {
        accessorKey: "time",
        id: "time",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "room_bookings",
        id: "room_bookings",
        header: "Raum",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>
                {row.original.room_bookings.map((r, index) => (
                    <span key={index}>
                        {index > 0 ? ", " : ""}
                        {r.room.name}
                    </span>
                ))}
            </>
        ),
    },
    {
        accessorKey: "category",
        id: "category",
        header: "Art",
        enableColumnFilter: false,
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
        headerClassNames: "medium-filter"
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Kurs",
        columnType: "m2mLinked",
        getItemLink: (item) => {
            return `/courses/${item.id}/`
        },
        labelAttr: "course_number",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Fach",
        cell: ({row, value}) => (
            row.original.courses?.map((c) =>
                c.school_subject).join(", ")
        ),
        enableColumnFilter: false,
    },
    {
        accessorKey: "teachers",
        id: "teachers",
        header: "Lehrer",
        columnType: "m2mLinked",
        getItemLink: (item) => {
            return `/staff/${item.id}/`
        },
        labelAttr: "full_name",
        enableColumnFilter: true,
        filterFn: "containsString",
    }
];

const editColumns = [
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
                <EditButton iconOnly={true} link={`/class-register/exams/${row.original.id}/edit`}/>
            </>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    }
]

export default function ExamList() {

    const [formData, setFormData] = useState({
        student_id: []
    });
    const [errors, setErrors] = useState({});
    const [debouncedStudentId, setDebouncedStudentId] = useState(formData.student_id);

    const {permissions} = useAuth();

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedStudentId(formData.student_id);
        }, 2000); // Adjust delay as needed (in milliseconds)

        // Cleanup the timeout if `formData.student_id` changes before the delay
        return () => {
            clearTimeout(handler);
        };
    }, [formData.student_id]);

    const {isLoading, isSuccess, data, refetch} = useQuery(
        ["exams", debouncedStudentId],
        () => getExamsWithSearch(debouncedStudentId),
        {refetchInterval: 1000 * 60 * 60}
    )

    const [studentSelectorOpen, setStudentSelectorOpen] = useState(false)
    const {data: students} = useQuery(["student_info_with_images"], getStudentInfoAllWithImages, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: groups} = useQuery(["student_info_grouped"], getStudentInfoGrouped, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: courses} = useQuery(["student_info_grouped_course"], getStudentInfoGroupedCourse, {refetchInterval: 1000 * 60 * 60 * 24})


    function getRowLink(row) {
        return `/class-register/exams/${row.original.id}/`;
    }

    function handleMultiSelectChange(value, fieldKey) {
        setFormData({
            ...formData,
            [fieldKey]: value,
        });
    }

    useEffect(() => {
        if (!studentSelectorOpen) {
            // do search here
            console.log('searching')
            refetch()
        }
    }, [studentSelectorOpen]);


    const canAddExam = checkPermission(permissions, "school.add_exam")
    const canEditExam = checkPermission(permissions, "school.edit_exam")

    const columnsCombined = canEditExam ? [...columns, ...editColumns] : columns

    return (
        <SimplePage>

            <StudentSelector
                open={studentSelectorOpen}
                setOpen={setStudentSelectorOpen}
                allStudents={students}
                setData={setFormData}
                data={formData}
                path="student_id"
                modalTitle="SchülerInnen auswählen"
                filters={{
                    ...groups,
                    ...courses,
                }}
                parentLabelAttr={{
                    courses: "course_number",
                    houses: "name",
                    school_classes: "group",
                    grades: "name",
                }}
            />

            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Klausuren
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <DownloadButton
                            apiFunction={() => getExamsExcel()}
                            fileName="Klausuren.xls"
                            label="Liste herunterladen"/>

                        <CalendarButton link={"/class-register/exams/calendar"} label="Kalender"/>

                        {canAddExam && <PrimaryButton link={"/class-register/exams/new"} label="Klausur eintragen"/>}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="mt-4 bg-gray-100 max-w-full py-2 align-middle md:px-6 lg:px-8">
                            <MultiSelect
                                path="student_id"
                                label="Klausuren nach ausgewählten SchülerInnen anzeigen:"
                                className="sm:col-span-6"
                                options={students}
                                valueAttr="id"
                                labelAttr="full_name"
                                onChange={(value) =>
                                    handleMultiSelectChange(value, "student_id")
                                }
                                errors={errors}
                                data={formData}
                                isClearable={false}
                                valueOnly={true}
                            />

                            <div className="mt-2 sm:col-span-6 flex justify-start items-end">
                                <button
                                    type="button"
                                    onClick={() => setStudentSelectorOpen(true)}
                                    className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 mr-3"
                                >Auswählen
                                </button>
                                {formData.student_id.length ? (
                                    <>
                                      <span className="bg-imsblue-100 text-imsblue-700 px-3 py-2 font-medium text-sm rounded-md">
                                          <span className="inline-flex justify-center items-center
                                          mr-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                            {formData.student_id.length}
                                          </span>
                                          SchülerInnen ausgewählt
                                       </span>
                                    </>
                                ) : ("") }
                            </div>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columnsCombined}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
