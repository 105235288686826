import React from 'react';
import {Route, Routes} from "react-router-dom";
import HouseList from "./HouseList";
import HouseDetail from "./HouseDetail";
import Teams from "./Teams";
import TeamDetail from "./TeamDetail";
import Smokers from "./Smokers";
import MainMentors from "./MainMentors";
import SupportPlanMeetings from "./SupportPlanMeetings";
import SupportPlanMeetingCreateEdit from "./SupportPlanMeetingCreateEdit";
import MyHouse from "./MyHouse";
import HouseStudentDetail from "./HouseStudentDetail";
import DepartureWeekends from "./DepartureWeekends";
import DepartureListEdit from "./DepartureListEdit";
import DepartureListDetail from "./DepartureListDetail";
import HouseLogEntries from "./HouseLogEntries";
import HouseLogEntryCreateEdit from "./HouseLogEntryCreateEdit";


function BoardingSchoolRouter() {

    return (
        <Routes>

            <Route path="/houses" element={<HouseList />} />
            <Route path="/houses/:houseId" element={<HouseDetail />} />

            <Route path="/houses/my" element={<MyHouse />} />
            {/*<Route path="/houses/my-houses" element={<MyHouses />} />*/}
            <Route path="/houses/students/:studentId" element={<HouseStudentDetail />} />

            <Route path="/teams" element={<Teams />} />
            <Route path="/teams/:teamId" element={<TeamDetail />} />

            <Route path="/smokers" element={<Smokers />} />
            <Route path="/stammmentoren" element={<MainMentors />} />

            <Route path="/support-plan-meetings" element={<SupportPlanMeetings />} />
            <Route path="/support-plan-meetings/new" element={<SupportPlanMeetingCreateEdit />} />
            <Route path="/support-plan-meetings/:id/edit" element={<SupportPlanMeetingCreateEdit />} />

            <Route path="/departure-lists" element={<DepartureWeekends />} />
            <Route path="/departure-lists/:weekendId" element={<DepartureListDetail />} />
            <Route path="/departure-lists/:weekendId/edit" element={<DepartureListEdit />} />

            <Route path="/houses/:houseId/house-log" element={<HouseLogEntries />} />
            <Route path="/houses/:houseId/house-log/new" element={<HouseLogEntryCreateEdit />} />
            <Route path="/house-log/:id/edit" element={<HouseLogEntryCreateEdit />} />

        </Routes>
    )
}


export default function BoardingSchool() {
    return <BoardingSchoolRouter />
}
