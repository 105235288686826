import React, {useEffect, useState} from "react";
import Alert from "../Alert";
import {validate, validateProperty, validateDateProperty} from "../../../utils/validation";

import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {DeleteButton} from "../DeleteButton";


export default function Form({
                                 data, setData,
                                 errors, setErrors,
                                 listErrors, setListErrors,
                                 setApiErrors, setApiSuccess,
                                 schema, submitButtonLabel, successMessage,
                                 onSave, afterSave, onDelete,
                                 hideSubmitButton, hideFormLayout, hasTopSubmitButton,
                                 children, ...rest
                             }) {

    const [success, setSuccess] = useState("") // overall success
    const [error, setError] = useState("") // API error
    const [loading, setLoading] = useState(false) // loading to prevent double submits

    async function handleSubmit(e) {

        e.preventDefault()
        setLoading(true)

        // validation of the entire form/schema
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Bitte korrigiere die markierten Felder.")
            if (setApiErrors) setApiErrors("Bitte korrigiere die markierten Felder.")
            setLoading(false)
            return
        } else {
            setError("")
        }

        try {
            setSuccess("")
            if (setApiSuccess) setApiSuccess("")
            await onSave()
            setSuccess(successMessage || "Erfolgreich gespeichert.")
            if (setApiSuccess) setApiSuccess(successMessage || "Erfolgreich gespeichert.")
        } catch (e) {
            //console.log(e)
            setError("Beim Speichern ist ein Fehler aufgetreten.")
            if (setApiErrors) setApiErrors("Beim Speichern ist ein Fehler aufgetreten.")
            // handle errors here and use setError
        }

        setLoading(false)

        if (afterSave) afterSave()
    }

    return (

        <form className="space-y-8 divide-y divide-gray-200"
              onSubmit={handleSubmit}
              {...rest}>

            {hasTopSubmitButton && <div className="text-right"
            ><button
                disabled={loading}
                type="submit"
                className="ml-3 justify-center align-middle py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
            >{submitButtonLabel || "Speichern"}
            </button></div>}

            {children}

            {!hideFormLayout && <div className="pt-5 divide-y divide-gray-200">

                <div className="flex justify-between">

                    {onDelete && data.id && <DeleteButton onDelete={onDelete} />}

                    <div className="">
                        {error && <Alert extraClassName="py-2" variant="danger">{error}</Alert>}
                        {success && <Alert extraClassName="py-2" variant="success">{success}</Alert>}
                    </div>

                    {!hideSubmitButton && <button
                        disabled={loading}
                        type="submit"
                        className="ml-3 justify-center align-middle py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                    >{submitButtonLabel || "Speichern"}
                    </button>}
                </div>
            </div>}

        </form>
    )
}


// TODO depcreate this and use the nested version instead
export function baseHandleChange(evt, data, setData, errors, setErrors, schema) {

    let value = evt.target.value;

    if (value === "") {
        value = null
    }

    if (evt.currentTarget && evt.currentTarget.type === 'checkbox') {
        setData({
            ...data,
            [evt.target.name]: evt.currentTarget.checked
        });
    } else {
        setData({
            ...data,
            [evt.target.name]: value
        });
    }

    // field validation
    setErrors({
        ...errors,
        [evt.target.name]: validateProperty(evt.target, schema, data)
    });
}


export function baseHandleChangeNested(evt, data, setData, errors, setErrors, schema) {
    let value = evt.target.value;

    if (value === "") {
        value = null;
    }

    const path = evt.target.name.split('.'); // Split the path into parts

    if (evt.currentTarget && evt.currentTarget.type === 'checkbox') {
        value = evt.currentTarget.checked;
    }

    const newData = {...data}; // Clone the data object
    let current = newData;

    for (let i = 0; i < path.length - 1; i++) {
        if (!current[path[i]]) {
            current[path[i]] = {}; // Create the object if it doesn't exist
        }
        current = current[path[i]];
    }

    current[path[path.length - 1]] = value; // Set the value on the nested object

    setData(newData);

    // Field validation
    setErrors({
        ...errors,
        [evt.target.name]: validateProperty(evt.target, schema, data)
    });
}


export function baseHandleListChange(rowId, evt, data, setData, errors, setErrors, schema, useStudentId=false) {

    const newData = data.map(row => {
        // console.log(row, rowId)
        if ((useStudentId ? row.student.id : row.id) === rowId) {
            var value = evt.target.value
            if (evt.currentTarget && evt.currentTarget.type === 'checkbox') {
                value = evt.currentTarget.checked
            }
            return {...row, [evt.target.name]: value}
        }
        return row
    })

    setData(newData)

    // field validation
    const newErrors = errors.map(error => {
        if ((useStudentId ? error.student.id : error.id) === rowId) {
            // Validate the field and update the `errors` object
            const validationError = validateProperty(evt.target, schema, data);
            return {
                ...error,
                errors: {
                    ...error.errors,
                    [evt.target.name]: validationError
                }
            };
        }
        return error;
    });

    // Ensure the row exists in errors if it doesn't already
    if (!newErrors.find(error => (useStudentId ? error.student.id : error.id) === rowId)) {
        const validationError = validateProperty(evt.target, schema, data);
        const newErrorEntry = {
            id: rowId,
            ...(useStudentId && { student: { id: rowId } }),
            errors: {
                [evt.target.name]: validationError
            }
        };
        newErrors.push(newErrorEntry);
    }

    setErrors(newErrors);

}

export function baseHandleListChangeNested(rowId, evt, data, setData, errors, setErrors, schema) {

    const path = evt.target.name.split('.'); // Split the path into parts

    const newData = data.map(row => {
        if (row.id === rowId) {
            let value = evt.target.value;
            if (evt.currentTarget && evt.currentTarget.type === 'checkbox') {
                value = evt.currentTarget.checked;
            }

            let current = row;
            for (let i = 0; i < path.length - 1; i++) {
                if (!current[path[i]]) {
                    current[path[i]] = {}; // Create the object if it doesn't exist
                }
                current = current[path[i]];
            }
            current[path[path.length - 1]] = value; // Set the value on the nested object

            return {...row};
        }
        return row;
    });

    setData(newData);

    // field validation
    // todo this has not been adjusted to the nesting yet
    const newErrors = errors ? errors.map(row => {
        if (row.id === rowId) {
            return {...row, [evt.target.name]: validateProperty(evt.target, schema, data)}
        }
        return row
    }) : errors
    setErrors(newErrors)

}

// todo add support for nested properties
export function baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, isDateTime=false) {

    let formattedDate
    try {
        formattedDate = moment(date).format(isDateTime ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD")
    } catch (e) {
        formattedDate = date;
    }
    setData({
        ...data,
        [path]: date ? formattedDate : date
    });

    // field validation
    setErrors({
        ...errors,
        [path]: validateDateProperty(formattedDate, path, schema, data)
    });
}


export function baseHandleDateListChange(rowId, date, path, data, setData, errors, setErrors, schema, isDateTime=false, useStudentId=false) {

    let formattedDate
    try {
        if (isDateTime) formattedDate = moment(date).format("YYYY-MM-DDTHH:mm");
        else formattedDate = moment(date).format("YYYY-MM-DD");
    } catch (e) {
        formattedDate = date;
    }

    const newData = data.map(row => {
        if ((useStudentId ? row.student.id : row.id) === rowId) {
            return {...row, [path]: formattedDate}
        }
        return row
    })

    setData(newData)

    const newErrors = errors ? errors.map(row => {
        if ((useStudentId ? row.student.id : row.id) === rowId) {
            return {...row, [path]: validateProperty(formattedDate, path, schema, data)}
        }
        return row
    }) : errors
    setErrors(newErrors)
}