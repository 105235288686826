import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {
    Checkbox,
    DateInput,
    DateTimeInput,
    Input,
    MultiSelect,
    Select,
    TextArea,
    TimeInput
} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {
    examApi,
    getExamEntryOpenWarnings,
    getExamTypeOptions,
    getExamWarnings, getGrades,
    getOtherExams
} from "../../../api/school";
import Form, {
    baseHandleChange,
    baseHandleDateChange,
    baseHandleDateListChange,
    baseHandleListChange
} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getBookableRooms, getRooms, roomBookingApi} from "../../../api/organizational";

import {
    AvailableRooms,
    FindProjectRoom,
    FindRoom,
    getProjectTimeFindRoomData
} from "../../modules/organizational/FindRoom";
import {DeleteButton} from "../../elements/DeleteButton";
import {AddButton} from "../../elements/Buttons";
import {getCourseList} from "../../../api/courses";
import {getDayPeriods, getWeekdayOptions} from "../../../api/calendar";
import ListViewTable from "../../elements/tables/ListViewTable";
import Alert from "../../elements/Alert";
import {getProjectCategories, getProjectToEdit, projectApi} from "../../../api/projects";
import {getStaffList} from "../../../api/staff";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";


export default function ProjectCreateEdit() {

    const {id} = useParams()

    const [data, setData] = useState({
        project_times: []
    })

    const {currentSchoolYear} = useSchoolYears();
    const {data: categories} = useQuery(['project_categories'], getProjectCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: grades} = useQuery(['grades'], getGrades, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: weekdays} = useQuery(['weekdays'], getWeekdayOptions, {refetchInterval: 1000 * 60 * 60 * 24})

    const [availableRooms, setAvailableRooms] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [findRoomData, setFindRoomData] = useState(null)
    const [newRowId, setNewRowId] = useState(1)
    const {data: rooms} = useQuery(["rooms"], getRooms, {refetchInterval: 1000 * 60 * 60 * 24});

    const [errors, setErrors] = useState({})
    const [listErrors, setListErrors] = useState([])
    const [bookingValidationErrors, setBookingValidationErrors] = useState([])

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getProjectToEdit(id)
        setData(newData)
    }

    const schema = {}

    const title = (id || data.id) ? "Projekt bearbeiten" : "Projekt eintragen"
    const successMessage = (id || data.id) ? "Projekt aktualisiert" : "Projekt eingetragen"

    async function onSave() {
        try {
            const tmpData = getTmpData()
            let newData
            if (!data.id) newData = await projectApi('post', null, tmpData)
            else newData = await projectApi('patch', null, tmpData)
            setBookingValidationErrors([])
            setData(newData)
            toast.success(successMessage)
        } catch (e) {
            if (e.data.room_booking_validation_errors) {
                setBookingValidationErrors(e.data.room_booking_validation_errors)
            }
            throw(e)
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.project_times, setProjectTimes, listErrors, setListErrors, schema)
    }

    function handleRoomChange(evt, rowId) {
        const newData = data.project_times.map(row => {
            if (row.id === rowId) {
                var value = evt.target.value
                return {
                    ...row,
                    room_bookings: row.room_bookings ? [{...row.room_bookings[0], room: value}] : [{room: value}]
                }
            }
            return row
        })
        setProjectTimes(newData)
    }

    function addRow() {
        const newRow = {
            id: `N${newRowId}`,
        }
        setSelectedRowId(`N${newRowId}`)
        setNewRowId(newRowId + 1)
        setData({
            ...data,
            project_times: [...data.project_times, newRow]
        })
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    function setProjectTimes(projectTimesData) {
        setData({...data, project_times: projectTimesData})
    }

    function getTmpData() {
        const newRoomBookings = data.project_times.map(row => {
            const newRow = {
                ...row,
                ...getProjectTimeFindRoomData(row, currentSchoolYear)
            }
            return newRow
        })
        const tmpData = {...data, project_times: newRoomBookings}
        return tmpData
    }

    function handleSelectRoom(room) {
        let selectedRowIdR = selectedRowId
        const newRoomBookings = data.project_times.map(row => {
            if (row.id === selectedRowIdR) {
                const newRow = {
                    ...row,
                    ...findRoomData,
                    room_bookings: row.room_bookings ? [{...row.room_bookings[0], room: room}] : [{room: room}]
                }
                return newRow
            }
            return row
        })
        setData({...data, project_times: newRoomBookings})
    }

    async function handleRoomBookingDelete(roomBookingId) {
        if (!roomBookingId.toString().startsWith("N")) {

            try {
                await roomBookingApi("delete", roomBookingId)
                // const newRoomBookings = data.room_bookings.filter(row => row.id !== roomBookingId)
                // setData({...data, room_bookings: newRoomBookings})
                loadData()
                toast.success("Raumbuchung gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="editProject" onSave={onSave}
                          onDelete={null}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <FormSectionContent>

                            <Input
                                path="name"
                                type="text"
                                label="Name"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <Input
                                path="name_for_certificate"
                                type="text"
                                label="Name im Zeugnis"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <TextArea
                                path="description"
                                type="text"
                                label="Beschreibung"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                rows={4}
                            />

                            <MultiSelect
                                path="categories"
                                label="Kategorien"
                                className="sm:col-span-3"
                                options={categories}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'categories')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <MultiSelect
                                path="grades"
                                label="Klassenstufen"
                                className="sm:col-span-3"
                                options={grades}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'grades')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <MultiSelect
                                path="project_leaders"
                                label="Projektleiter"
                                className="sm:col-span-6"
                                options={staff}
                                valueAttr="id"
                                labelAttr="full_name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'project_leaders')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />


                            <Input
                                path="contribution_to_costs"
                                type="text"
                                label="Kostenbeitrag im Halbjahr"
                                className="sm:col-span-3"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <Input
                                path="max_participants"
                                type="number"
                                label="Maximale Teilnehmerzahl"
                                className="sm:col-span-3"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <div className="sm:col-span-6 flex gap-4">
                                <Checkbox
                                    path="has_student_times"
                                    label="Einzelzeiten"
                                    onChange={handleChange}
                                    data={data}
                                    errors={errors}
                                    hideLegend={true}
                                />
                                <Checkbox
                                    path="is_music_lessons"
                                    label="Einzelmusikunterricht"
                                    onChange={handleChange}
                                    data={data}
                                    errors={errors}
                                    hideLegend={true}
                                />
                            </div>


                            <div className="sm:col-span-6 py-4 border-y divide-gray-100">

                                <h3 className="text-lg leading-6 font-medium text-gray-900">Zeiten & Raumbuchung</h3>

                                {bookingValidationErrors &&
                                    <div className="my-2 px-2 bg-red-50 text-red-700 sm:col-span-6">
                                        <ul role="list" className="space-y-1">
                                            {bookingValidationErrors.map((error, index) => (
                                                <li key={index} className="flex py-2">{error.error}: &nbsp;
                                                    <Link className="font-medium underline"
                                                          target={"_blank"}
                                                          to={`/service/room-bookings/calendar/?event_id=${error.room_booking.id}`}>
                                                        {error.room_booking?.id} von {error.room_booking?.created_by_full_name} <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-flex align-middle"/>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>}

                                {data && data.project_times?.map((projectTime, index) => (
                                    <div key={index} className="flex gap-2 my-4">

                                        <Select
                                            path="weekday"
                                            label="Wochentag"
                                            options={weekdays}
                                            labelAttr="label"
                                            valueAttr="value"
                                            onChange={handleListChange}
                                            data={data.project_times}
                                            errors={listErrors}
                                            isClearable={false}
                                            valueOnly={true}
                                            rowId={projectTime.id}
                                        />

                                        <Input
                                            path="start_time"
                                            type="text"
                                            label="Von"
                                            placeholder="HH:MM"
                                            className="w-24"
                                            onChange={handleListChange}
                                            data={data.project_times}
                                            errors={listErrors}
                                            rowId={projectTime.id}
                                        />
                                        <Input
                                            path="end_time"
                                            type="text"
                                            label="Bis"
                                            placeholder="HH:MM"
                                            className="w-24"
                                            onChange={handleListChange}
                                            data={data.project_times}
                                            errors={listErrors}
                                            rowId={projectTime.id}
                                        />

                                        <Select
                                            path="room_bookings[0].room"
                                            label="Raum"
                                            className="flex-grow"
                                            options={rooms}
                                            valueAttr="id"
                                            labelAttr="name"
                                            onChange={handleRoomChange}
                                            data={data.project_times}
                                            errors={listErrors}
                                            isClearable={true}
                                            valueOnly={false}
                                            rowId={projectTime.id}
                                        />

                                        <div className="align-bottom flex-grow-0 pt-6">
                                            <FindProjectRoom projectTime={projectTime}
                                                             setFindRoomData={setFindRoomData}
                                                             setSelectedRowId={setSelectedRowId}
                                                             setAvailableRooms={setAvailableRooms}
                                                             errors={errors}
                                                             setErrors={setErrors}/>


                                            <div className="">
                                                {projectTime.room_bookings && projectTime.room_bookings.length > 0 && projectTime.room_bookings[0].id &&
                                                    <DeleteButton iconOnly={false}
                                                                  label={"Raumbuchung löschen"}
                                                                  onDelete={() => handleRoomBookingDelete(projectTime.room_bookings[0].id)}/>}
                                            </div>

                                        </div>

                                    </div>))}


                                <div className="flex justify-start gap-4 items-end sm:col-span-2">


                                    <AddButton onClick={addRow} label="Zeit hinzufügen"/>
                                </div>

                            </div>

                        </FormSectionContent>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

                <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">

                    <div className="relative mb-8">
                        <AvailableRooms availableRooms={availableRooms}
                                        msg="Je Projektzeit 'Raum finden' klicken, um hier verfügbare Räume anzuzeigen."
                                        onRoomSelect={handleSelectRoom}/>
                    </div>

                </div>
            </div>


        </Page>
    )
}
