import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";
import httpNoAuthService from "../services/httpNoAuthService";

export async function getProjectCategories() {
    const resp = await http.get('/project_categories/')
    return resp.data
}

export async function getProjectList() {
    const resp = await http.get('/projects/project_list/')
    return resp.data
}

export async function getProjectListProjectLeader() {
    const resp = await http.get('/projects/project_list_project_leader/')
    return resp.data
}

export async function getProjects() {
    const resp = await http.get('/projects/')
    return resp.data
}

export async function projectApi(method, id, data) {
    return genericApi('/projects/', method, id, data)
}

export async function getProjectToEdit(id) {
    const resp = await http.get(`/projects/${id}/get_to_edit/`)
    return resp.data
}

export async function getProject(id) {
    const resp = await http.get(`/projects/${id}/`)
    return resp.data
}

export async function cancelProject(id) {
    const resp = await http.patch(`/projects/${id}/cancel/`)
    return resp.data
}

export async function restartProject(id) {
    const resp = await http.patch(`/projects/${id}/restart/`)
    return resp.data
}

export async function getParticipantListPDF(projectId) {
    const resp = await httpServiceDownloads.get(`/projects/${projectId}/participants_list_pdf/`);
    return resp.data;
}

export async function getAttendanceListPDF(projectId) {
    const resp = await httpServiceDownloads.get(`/projects/${projectId}/attendance_list_pdf/`);
    return resp.data;
}


export async function getProjectsExcel() {
    const resp = await httpServiceDownloads.get(`/projects/export/`);
    return resp.data;
}

export async function getProjectParticipationExcel() {
    const resp = await httpServiceDownloads.get(`/projects/export_project_participation/`);
    return resp.data;
}

export async function getParticipantsExcel() {
    const resp = await httpServiceDownloads.get(`/projects/export_participants/`);
    return resp.data;
}


// Students & participation
export async function getProjectStudents() {
    const resp = await http.get(`/project_participations/students/`)
    return resp.data
}

export async function getProjectStudent(studentId) {
    const resp = await http.get(`/project_participations/students/?student_id=${studentId}`)
    return resp.data.length > 0 ? resp.data[0] : resp.data
    // return resp.data[0]
}

export async function getProjectParticipationStudentDetail(id) {
    const resp = await http.get(`/project_participations/?student_id=${id}`)
    return resp.data
}

export async function getProjectParticipation(id) {
    const resp = await http.get(`/project_participations/${id}/`)
    return resp.data
}

export async function postProjectParticipation(data) {
    const resp = await http.post(`/project_participations/`, data)
    return resp.data
}

export async function patchProjectParticipation(data) {
    const resp = await http.patch(`/project_participations/${data.id}/`, data)
    return resp.data
}


export async function endProjectParticipation(id) {
    const resp = await http.patch(`/project_participations/${id}/end/`)
    return resp.data
}

export async function restartProjectParticipation(id) {
    const resp = await http.patch(`/project_participations/${id}/restart/`)
    return resp.data
}

export async function deleteProjectParticipation(id) {
    const resp = await http.delete(`/project_participations/${id}/`)
    return resp.data
}

// attendance

export async function getAbsenceNoticesAndPeExemptions(projectId, date) {
    const resp = await http.get(`/projects/${projectId}/absences_notices_and_pe_exemptions/?date=${date}`)
    return resp.data
}

export async function getAttendanceStudents() {
    const resp = await http.get(`/project_attendance_students/`)
    return resp.data
}

export async function getAttendanceStudentsMusic() {
    const resp = await http.get(`/project_attendance_students/music/`)
    return resp.data
}

export async function getAttendanceStudent(studentId) {
    const resp = await http.get(`/project_attendance_students/${studentId}/`)
    return resp.data
}

export async function getAttendanceLinesForStudent(studentId, music) {
    const resp = await http.get(`/project_attendance_lines/?student_id=${studentId}&music=${music}`)
    return resp.data
}


export async function getProjectAttendances(projectId) {
    const resp = await http.get(`/project_attendance/` + (projectId ? `?project_id=${projectId}` : ''))
    return resp.data
}

export async function getProjectAttendanceList(projectId) {
    const resp = await http.get(`/projects/${projectId}/attendance_list/`)
    return resp.data
}


export async function getProjectAttendance(id) {
    const resp = await http.get(`/project_attendance/${id}/`)
    return resp.data
}

export async function postProjectAttendance(data) {
    const resp = await http.post(`/project_attendance/`, data)
    return resp.data
}

export async function patchProjectAttendance(data) {
    const resp = await http.patch(`/project_attendance/${data.id}/`, data)
    return resp.data
}

export async function deleteProjectAttendance(id) {
    const resp = await http.delete(`/project_attendance/${id}/`)
    return resp.data
}

// reports

export async function getCancelledProjectsReport() {
    const resp = await http.get(`/projects/reports/cancelled_projects/`)
    return resp.data
}

export async function getCancelledProjectsReportExcel() {
    const resp = await httpServiceDownloads.get(`/projects/reports/cancelled_projects/excel/`)
    return resp.data
}

export async function getProjectAbsenceReasons() {
    const resp = await http.get('/project_absence_reasons/')
    return resp.data
}

export async function getProjectAbsencesReport() {
    const resp = await http.get(`/projects/reports/project_absences/`)
    return resp.data
}

export async function getProjectAbsencesReportExcel() {
    const resp = await httpServiceDownloads.get(`/projects/reports/project_absences/excel/`)
    return resp.data
}

export async function getProjectsReport() {
    const resp = await http.get(`/projects/reports/projects/`)
    return resp.data
}

export async function getProjectsReportExcel() {
    const resp = await httpServiceDownloads.get(`/projects/reports/projects/excel/`)
    return resp.data
}

// project cancellations
export async function projectCancellationApi(method, id, data) {
    return genericApi('/project_cancellations/', method, id, data)
}

export async function getProjectFeed() {
    const resp = await http.get('/project_participations/feed/')
    return resp.data
}

export async function createInvoiceForProject(id) {
    const resp = await http.post(`/projects/${id}/create_invoices/`)
    return resp.data
}

export async function getProjectInvoicePDF(invoiceId) {
    const resp = await httpServiceDownloads.get(`/project_invoices/${invoiceId}/pdf/`)
    return resp.data
}

export async function getProjectsStudentsPDF() {
    const resp = await httpServiceDownloads.get('/projects/student_list_pdf/');
    return resp.data;
}

export async function getProjectParticipationChangesExcel() {
    const resp = await httpServiceDownloads.get(`/projects/project_participation_changes/excel/`)
    return resp.data
}
