import {SimplePage} from "../../elements/Pages";
import React from "react";
import ListViewTable, {TwoLineColumn, TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import {getStudentCourseMaterials} from "../../../api/courses";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getStudentInfo} from "../../../api/school";

const columns = [
    {
        accessorKey: "course.course_number",
        id: "course_number",
        header: "Kurs",
        enableColumnFilter: true,
        cell: ({row, value}) => {

            return <TwoLineColumnWithLink key={row.original.id}
                                          target="_self"
                                          link={`/courses/${row.original.course.id}`}
                                          line1={`${row.original.course.course_number}`}
                                          line2={`${row.original.course.school_subject}`}
            />
        }
    },
    {
        accessorKey: "get_file_name",
        id: "get_file_name",
        header: "Datei",
        cell: ({row, value}) => (
            row.original.course_material_files.length > 0 ?
                row.original.course_material_files.map((f, index) => {
                    return <TwoLineColumnWithLink key={index}
                                                  link={`${f.file}`}
                                                  line1={`${row.original.title}`}
                                                  line2={`${f.get_file_name}`}
                    />
                }) : <TwoLineColumn line1={`${row.original.title}`} />
        ),
        enableColumnFilter: false,
        classNames: "max-w-xs text-gray-500",
        disableRowLink: true
    },
    {
        accessorKey: "visible_from",
        id: "visible_from",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        enableColumnFilter: false,
    },
    {
        accessorKey: "description",
        id: "description",
        header: "Beschreibung",
        enableColumnFilter: false,
        classNames: "max-w-md",
    }
];

export default function MyCourseMaterialsStudent() {

    const {studentId} = useParams()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["course_materials_for_student", studentId],
        () => getStudentCourseMaterials(studentId),
        {}
    )

    const {data: student} = useQuery(["student_info", studentId], () =>
            getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Aktuelle Kursmaterialien {student && <>von {student.full_name}</>}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
