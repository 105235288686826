import {DetailPage} from "../../elements/Pages";
import {React} from 'react'

import {useQuery} from '@tanstack/react-query'
import {getKitchenMealtimes} from "../../../api/organizational";


export default function KitchenMealtimes({isDashboard}) {

    const {data} = useQuery(['kitchen_mealtimes'], getKitchenMealtimes, {refetchInterval: 1000 * 60 * 60})

    if (!data) return <></>

    const headerElement = <div
        className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
            <h1 className="text-2xl font-bold text-gray-900">Schülerzahlen zu Mahlzeiten</h1>
        </div>
        <div
            className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        </div>
    </div>

    const content = <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full table-fixed ">
                <thead>
                <tr className="bg-gray-50">
                    <th className="pl-5 py-3.5 text-left text-xs"></th>
                    {data.map((d, index) => (
                        <th key={index} className="py-3.5 text-left text-xs">{d.date}</th>
                    ))}
                </tr>
                </thead>
                <tbody>

                <tr>
                    <td className="pl-5 py-4 text-sm font-medium">Frühstück</td>
                    {data.map((d, index) => (
                        <td key={index} className="py-4 text-sm">{d.breakfast}</td>
                    ))}
                </tr>
                <tr>
                    <td className="pl-5 py-4 text-sm font-medium">Mittagessen</td>
                    {data.map((d, index) => (
                        <td key={index} className="py-4 text-sm">{d.lunch}</td>
                    ))}
                </tr>
                <tr>
                    <td className="pl-5 py-4 text-sm font-medium">Abendessen</td>
                    {data.map((d, index) => (
                        <td key={index} className="py-4 text-sm">{d.dinner}</td>
                    ))}
                </tr>
                <tr>
                    <td className="pl-5 py-4 text-sm font-medium">Lunchpakete</td>
                    {data.map((d, index) => (
                        <td key={index} className="py-4 text-sm">{d.packed_lunches}</td>
                    ))}
                </tr>


                </tbody>
            </table>

        </div>
    </section>

    if (isDashboard) {
        return (
            <div className="bg-white shadow sm:rounded-lg">
                {content}
            </div>
        )
    }

    return (

        <DetailPage>
            {headerElement}
            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-3">
                    {content}
                </div>
            </div>
        </DetailPage>
    )
}
