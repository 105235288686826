import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {getHouseDetail, getHouseExcel, getHouseLogEntries, getHouseLogEntryExcel} from "../../../api/boarding_school";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Eintrag",
        cell: ({row, value}) => (
            <TwoLineColumn
                line1={row.original.title}
                line2={row.original.entry}
            />),
        enableColumnFilter: false,
        classNames: "max-w-lg text-gray-500",
        headerClassNames: "max-w-lg",
    },
    {
        accessorKey: "created_by",
        id: "created_by",
        header: "Erstellt von",
        columnType: "user",
        enableColumnFilter: false,
    },
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: (info) => (
            <TableEditLink
                label="Bearbeiten"
                iconOnly={true}
                to={`/boarding-school/house-log/${info.getValue()}/edit`}
            />
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    }
];

export default function HouseLogEntries() {

    const {houseId} = useParams();
    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["house_log_entries", houseId],
        () => getHouseLogEntries(houseId),
    )

    const {data: house} = useQuery(["house_detail", houseId], () => getHouseDetail(houseId), {refetchInterval: 1000 * 60 * 60 * 24});

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Dienstbucheinträge {house && "von " + house?.name}
                        </h3>
                    </div>

                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">

                        <DownloadButton
                            apiFunction={() => getHouseLogEntryExcel(houseId)}
                            fileName={`Dienstbuch.xls`}
                            label="Herunterladen"/>

                        {checkPermission(
                            permissions,
                            "boarding_school.add_houselogentry"
                        ) && (
                            <Link to={`/boarding-school/houses/${houseId}/house-log/new`}>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
