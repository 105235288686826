import {SimplePage} from "../../elements/Pages";
import React, {Fragment} from "react";

import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import {getPlupuStaffDetailExcel, getPlupuStaffListExcel, getStaffDetail} from "../../../api/plupus";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorFn: (row) => `${row.student_last_name}, ${row.student_first_name} ${row.student_number}`,
        id: "student_search",
        header: "Name",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'type',
        id: 'type',
        header: 'Eintrag',
        enableColumnFilter: true
    },
    {
        accessorKey: 'total_points',
        id: 'points',
        header: 'Punkte',
        enableColumnFilter: false
    },
    {
        accessorKey: 'date',
        id: 'date',
        header: 'Datum',
        enableColumnFilter: false,
        columnType: 'date'
    },
    {
        accessorKey: 'time_period',
        id: 'time_period',
        header: 'Epoche',
        enableColumnFilter: true
    }
];

export default function StaffDetail() {

    const {staffId} = useParams()
    const {renderUserImage} = useUserInfo()

    const {isLoading, isSuccess, data} = useQuery(
        ["plupus_staff_detail", staffId],
        () => getStaffDetail(staffId)
    );

    function getRowLink(row) {
        if (row.original.type === 'Logbucheintrag') {
            return `/logbook/entries/${row.original.entry_id}`;
        }
        return `/class-register/entries/${row.original.entry_id}`;
    }

    if (!data) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center justify-between">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(data.staff.user_id, 80)}
                                <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"/>
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                <Link to={`/staff/${data.staff.id}`}>{data.staff.full_name}</Link>
                            </h1>
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getPlupuStaffDetailExcel(staffId)}
                            fileName={`GegebenePlupus ${data.staff.full_name}.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.entries}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
