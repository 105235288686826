import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, Input, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getHouseDetail, houseLogEntryApi} from "../../../api/boarding_school";
import moment from "moment";

export default function HouseLogEntryCreateEdit() {

    const queryClient = useQueryClient()
    
    const {id, houseId} = useParams()
    const [data, setData] = useState({
        id: null,
        house_id: houseId ? parseInt(houseId) : null,
        title: "",
        entry: "",
        date: moment().format("YYYY-MM-DD"),
    })

    const {data: house} = useQuery(["house_detail", getHouseDetail], () => getHouseDetail(houseId), {refetchInterval: 1000 * 60 * 60 * 24});
    
    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await houseLogEntryApi('get', id)
        setData(newData)
    }
    
    const schema = {
        title: Joi.string(),
        entry: Joi.string().required(),
        date: Joi.date().required()
    }

    const title = (id || data.id) ? "Dienstbucheintrag bearbeiten" : "Neuen Dienstbucheintrag erstellen"
    const successMessage = (id || data.id) ? "Dienstbucheintrag aktualisiert" : "Neuen Dienstbucheintrag erstellt"

    async function onSave() {
        if (!data.id) await houseLogEntryApi('post', null, data)
        else await houseLogEntryApi('patch', data.id, data)
        queryClient.invalidateQueries({queryKey: ['house_log_entries', houseId]})
        toast.success(successMessage)
        navigate(`/boarding-school/houses/${data.house_id}/house-log`)
    }

    async function onDelete() {
        await houseLogEntryApi('delete', data.id)
        queryClient.invalidateQueries({queryKey: ['house_log_entries', houseId]})
        toast.success("Dienstbucheintrag gelöscht.")
        navigate(`/boarding-school/houses/${data.house_id}/house-log`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    return (

        <>
            <NarrowPage title={title}>

                <Form id="createHouseLogEntry" onSave={onSave}
                      onDelete={onDelete}
                      data={data} setData={setData}
                      errors={errors} setErrors={setErrors}
                      successMessage={successMessage}
                      schema={schema}>

                    <FormSectionContent>

                        {house && <p className="sm:col-span-6 font-medium">Mentorat: {house?.name}</p>}

                        <DateInput
                            path="date"
                            label="Datum"
                            className="sm:col-span-3"
                            onChange={handleDateChange}
                            errors={errors}
                            data={data}
                            todayButton={true}
                            tomorrowButton={false}
                        />

                        <Input
                            path="title"
                            type="text"
                            label="Titel"
                            className="sm:col-span-6"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />

                        <TextArea
                            path="entry"
                            label="Eintrag"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            rows={10}
                            className="sm:col-span-6"
                            helpText=""
                        />

                    </FormSectionContent>

                </Form>

                <span onClick={() => navigate(-1)}
                      className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

            </NarrowPage>
        </>
    )
}
